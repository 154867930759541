import genericError from '../Assets/Img/404Error.svg'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
import { useLocation, useNavigate } from 'react-router-dom'

export const ServiceError = () => {
    let navigate = useNavigate()
    let location = useLocation()
    let suiteApp = '/suite-apps'
    return (
        <section id='service-error'>
            <div className='image'>
                <img src={genericError} alt='Error' />
            </div>
            <div className='number'>404</div>
            <div className='text'>
                La página que intentas visitar no existe.
            </div>
            <Button onClick={() => navigate(-1)} type={'primary'}>
                Volver
            </Button>
        </section>
    )
}
