import { BrowserRouter } from 'react-router-dom'
import { ServiceError } from './Components/ServiceError'

export default function Root(props) {
    return (
        <BrowserRouter>
            <ServiceError />
        </BrowserRouter>
    )
}
